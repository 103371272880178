<template>
  <div class="son-page">
    <div class="top-change">
      <div class="top-search">
        <DatePicker @on-change="time" type="daterange" placement="bottom" placeholder="点击选择日期" style="width: 240px"></DatePicker>
        <Button @click="searchBtn" style="margin-left:30px" class="btn">搜索</Button>
      </div>
    </div>
    <div class="view_box">
      <div class="item_view view_left">
        <div class="nei_left">
          <Pie v-if="status" :color="['#5DAEFF','#FF761D','#92C411','#FF771C']" :num="data.total_member" :data="piedata" :title="'总用户'" :width="'300px'" :height="'200'"></Pie>
        </div>
        <div class="nei_right">
          <div class="view_title">
            <div class="w33">用户</div>
            <div class="w33">数量</div>
            <div class="w33">占比</div>
          </div>
          <div class="view_shuju">
            <div style="display:flex;align-items: center;justify-content: center;" class="w33 dian">会员用户</div>
            <div class="w33">{{data.full_member}}</div>
            <div class="w33">{{data.number_proportion}}</div>
          </div>
          <div class="view_shuju">
            <div style="display:flex;align-items: center;justify-content: center;" class="w33 dianH">普通用户</div>
            <div class="w33">{{data.full_person}}</div>
            <div class="w33">{{data.person_proportion}}</div>
          </div>
        </div>
      </div>
      <div class="item_view view_right">
        <div style="display:flex;align-items: center;" class="Line"><span class="Line_Txt">用户数据</span></div>
        <div class="view_right_num">
          <div style="border-right: 1px dashed #999;" class="item_right_num">
            <div>今日新增用户</div>
            <div class="item_num">{{data.now_today_person}}</div>
          </div>
          <div style="border-right: 1px dashed #999;" class="item_right_num">
            <div>今日新增会员</div>
            <div class="item_num">{{data.now_today_member}}</div>
          </div>
          <div class="item_right_num">
            <div>今日新增</div>
            <div class="item_num">{{data.now_today_person}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content_box">
      <EchartLine v-if="status" :xAxis="xAxis" :series="series" id="asd" width="100%"></EchartLine>
    </div>
  </div>
</template>

<script>
import {homeIndex} from '@/api/user'
import Pie from '../../../components/eChart/pie.vue';
import {EchartLine} from '@/components/eChart/index'
export default {
  components:{Pie,EchartLine},
  name: "canteen",
  data(){
    return{
      data:{},
      status:false,
      storePrams:{
        start_time:'',
        end_time:''
      },
      piedata:[
        {value: 1048, name: '会员用户'},
        {value: 1048, name: '普通用户'}
      ],
      xAxis:[],
      series:[{
          type:'line',
          data:[]
      }]
    }
  },
  created() {
    this.getList()
  },
  methods:{
    getList(){
      homeIndex(this.storePrams).then(res=>{
        var a = res.data.number_proportion.split("%").join("");
        var b = res.data.person_proportion.split("%").join("");
        this.piedata[0].value = Number(a)
        this.piedata[1].value = Number(b)
        res.data.line_chart.forEach(item => {
          this.xAxis.push(item.date)
          this.series[0].data.push(item.val)
        });
        this.data = res.data
        this.status = true
      })
    },
    searchBtn(){
      this.getList()
    },
    time(e){
      this.storePrams.start_time = e[0]
      this.storePrams.end_time = e[1]
    }
  }
}
</script>

<style scoped>
/* .son-page {

} */
.top-change {
  width: 100%;
  background: white;
  border-radius: 12px;
  padding: 20px 30px;
  box-sizing: border-box;
}
.top-search {
  width: 100%;
  background: white;
  padding: 20px 0;
  border-radius: 10px;
  display: flex;
}
.btn{
  background:#FF761D;
  color:#FFF;
  border-color: #FF761D;
}
.view_box{
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 12px;
  margin: 30px 0;
}
.content_box{
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 12px;
  background: #FFF;
  margin: 30px 0;
}
.item_view{
  width: 48%;
  background: #fff;
  border-radius: 12px;
}
.view_title{
  background: #F6F6F6;
  display: flex;
  color: #999;
  justify-content: space-around;
}
.view_shuju{
  padding: 5px 0;
  display: flex;
  justify-content: space-around;
}
.w33{
  width: 33.3%;
  text-align: center;
}
.nei_left{
  width: 50%;
  display: flex;
  justify-content: center;
}
.nei_right{
  width: 50%;
}
.dianH::before{
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 5px;
  background: #FF761D;
}
.dian::before{
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 5px;
  background: #5DAEFF;
}
.view_left{
  min-width: 470px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  box-sizing: border-box;
}
.view_right{
  min-width: 470px;
  padding: 30px 20px;
}
.Line::before{
  content: '';
  display: block;
  width: 3px;
  height: 20px;
  background: #FF761D;
  margin-right: 10px;
}
.Line_Txt{
  font-size: 20px;
  font-weight: 700;
}
.view_right_num{
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.item_right_num{
  width: 33.3%;
  text-align: center;
}
.item_num{
  margin-top: 10px;
  font-weight: 700;
  font-size: 20px;
}
</style>
