<template>
    <div v-if="showModal" class="out-pop">
        <div class="pop"></div>
        <div class="pop-card">
            <div v-if="info.business_status == -1" class="status-pic">
                <img src="../../assets/status/1.png" />
            </div>
            <div v-if="info.business_status == 6" class="status-pic">
                <img src="../../assets/status/2.png" />
            </div>
            <div v-if="info.business_status == 1" class="status-pic">
                <img src="../../assets/status/3.png" />
            </div>
            <div v-if="info.business_status == 3" class="status-pic">
                <img src="../../assets/status/4.png" />
            </div>
            <div v-if="info.business_status == 2" class="status-pic">
                <img src="../../assets/status/5.png" />
            </div>
            <div v-if="info.business_status == 4" class="status-pic">
                <img src="../../assets/status/6.png" />
            </div>
            <div v-if="info.business_status == 5" class="status-pic">
                <img src="../../assets/status/7.png" />
            </div>
            <div @click="closeModal" class="close">
                <Icon type="md-close" size="30" />
            </div>
            <div v-if="info.type == 1" class="title">堂食订单</div>
            <div v-if="info.type == 2" class="title">外卖订单</div>
            <div v-if="info.type == 3" class="title">自取订单</div>

            <div style="max-height: 700px; overflow: auto; padding: 20px 5px">
                <div v-if="info.type == 1 && info.desk" class="desk_number">
                    <span style="font-size: 20px">{{ info.desk }}</span> 号桌
                </div>
                <div v-if="info.type == 3 && info.z_number" class="desk_number">
                    <span style="font-size: 20px"
                        >取餐码：{{ info.z_number }}</span
                    >
                </div>
                <div
                    style="
                        display: flex;
                        justify-content: space-between;
                        font-size: 16px;
                    "
                >
                    <div>单号 : {{ info.number }}</div>
                    <div>订单编号 : {{ info.number_no }}</div>
                </div>

                <div
                    class="content"
                    style="display: flex; justify-content: space-between"
                >
                    <div>用户编号 : {{ info.member_number }}</div>
                    <div>会员姓名 : {{ info.name || "暂无" }}</div>
                </div>
                <div
                    v-if="info.type != 1"
                    class="content"
                    style="display: flex; justify-content: space-between"
                >
                    <div v-if="info.w_name">姓名 : {{ info.w_name }}</div>
                    <div v-if="info.w_phone">联系方式 : {{ info.w_phone }}</div>
                </div>
                <div
                    v-if="info.type == 3"
                    class="content"
                    style="display: flex; justify-content: space-between"
                >
                    <div v-if="info.get_name">姓名 : {{ info.get_name }}</div>
                    <div v-if="info.get_phone">
                        联系方式 : {{ info.get_phone }}
                    </div>
                </div>

                <div v-if="info.type != 1 && info.w_address" class="content">
                    地址 : {{ info.w_address }}
                </div>
                <div v-if="info.type == 3 && info.pickup_address" class="content" style="margin-top: 10px;">
                    <span class="invite_span">自取点 : </span>{{ info.pickup_address }}
                </div>
                <div v-if="info.type == 3 && info.pickup_title" class="content">
                    <span class="invite_span">自取地址 : </span>{{ info.pickup_title }}
                </div>
                <div style="margin-top: 20px">
                    <div
                        style="
                            display: flex;
                            justify-content: space-between;
                            font-size: 16px;
                            margin-bottom: 10px;
                        "
                    >
                        <div
                            class="order-title"
                            v-if="info.type == 1 || info.type == 2"
                        >
                            订单明细（共{{ info.goods.length }}份）
                        </div>
                        <div
                            class="order-title"
                            v-if="info.type == 3 && info.z_eat_type == 1"
                        >
                            店内就餐（共{{ info.goods.length }}份）
                        </div>
                        <div
                            class="order-title"
                            v-if="info.type == 3 && info.z_eat_type == 2"
                        >
                            打包外带（共{{ info.goods.length }}份）
                        </div>
                        <div class="order-time">
                            下单时间：{{ info.created_at }}
                        </div>
                    </div>
                    <div
                        style="display: flex; justify-content: space-between"
                        class="item-shops"
                        v-for="(item, index) of info.goods"
                        :key="index"
                    >
                        <div>
                            {{ item.goods_name }} ({{ item.prices.specs }})
                            <span v-for="(a, s) of item.attrs" :key="s"
                                >{{ a.name }}：{{ a.val }} &nbsp; &nbsp;</span
                            >
                            <span v-for="(f, fs) of item.foods" :key="fs"
                                >{{ f.name }}
                            </span>
                            <span style="font-weight: 700; margin-left: 10px"
                                >x {{ item.num }}</span
                            >
                        </div>
                        <div style="font-weight: 700; margin-left: 10px">
                            ￥{{ item.prices.price }}
                        </div>
                    </div>
                    <div
                        style="border-top: 1px dashed #999; margin: 10px 0"
                    ></div>
                    <div style="color: #333">
                        <!-- <div style="display:flex;justify-content: space-between;">
                <div>商家优惠券</div>
                <div style="font-weight:700;">-￥20</div>
              </div> -->
                        <div
                            style="
                                display: flex;
                                justify-content: space-between;
                            "
                        >
                            <div>商品金额</div>
                            <div style="font-weight: 700">
                                ￥{{
                                    (sumGodsMoney(info.goods) +
                                        parseFloat(info.discount_money))
                                        | numberToFixed
                                }}
                            </div>
                        </div>
                        <div
                            v-if="
                                info.type == 2 ||
                                (info.type == 3 && info.z_eat_type != 1)
                            "
                            style="
                                display: flex;
                                justify-content: space-between;
                            "
                        >
                            <div>打包费</div>
                            <div style="font-weight: 700">
                                ￥{{ sumPackMoney(info.goods) }}
                            </div>
                        </div>
                        <div
                            v-if="info.discount_money > 0"
                            style="
                                display: flex;
                                justify-content: space-between;
                            "
                        >
                            <div>会员折扣</div>
                            <div style="font-weight: 700">
                                -￥{{ info.discount_money | numberToFixed }}
                            </div>
                        </div>
                        <div
                            style="
                                display: flex;
                                justify-content: space-between;
                            "
                        >
                            <div>总计</div>
                            <div
                                v-if="
                                    info.type == 1 ||
                                    (info.type == 3 && info.z_eat_type == 1)
                                "
                                style="font-weight: 700"
                            >
                                ￥{{ sumGodsMoney(info.goods) | numberToFixed }}
                            </div>
                            <div v-else style="font-weight: 700">
                                ￥{{
                                    (sumGodsMoney(info.goods) +
                                        sumPackMoney(info.goods))
                                        | numberToFixed
                                }}
                            </div>
                        </div>
                        <div style="display: flex; justify-content: flex-end">
                            <div
                                :style="
                                    info.pay_status == 1
                                        ? 'color:red'
                                        : 'color:#01B581'
                                "
                            >
                                {{ info.pay_status == 1 ? "未付款" : "已付款" }}
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="info.remarks"
                        style="display: flex; margin-top: 5px"
                    >
                        <div style="width：100px">备注信息：</div>
                        <div style="flex: 1">{{ info.remarks }}</div>
                    </div>
                </div>

                <div v-if="info.branch.length != 0">
                    <div
                        v-for="(item, index) of info.branch"
                        :key="index"
                        style="margin-top: 10px"
                    >
                        <div
                            style="
                                display: flex;
                                justify-content: space-between;
                                font-size: 16px;
                                margin-bottom: 10px;
                            "
                        >
                            <div class="order-title">
                                加菜明细（共{{ item.goods_list.length }}份）
                            </div>
                            <div class="order-time">
                                下单时间：{{ item.created_at }}
                            </div>
                        </div>
                        <div
                            style="
                                display: flex;
                                justify-content: space-between;
                            "
                            class="item-shops"
                            v-for="(item, index) of item.goods_list"
                            :key="index"
                        >
                            <div>
                                {{ item.goods_name }} ({{ item.prices.specs }})
                                <span v-for="(a, s) of item.attrs" :key="s"
                                    >{{ a.name }}：{{ a.val }} &nbsp; &nbsp;</span
                                >
                                <span
                                    style="font-weight: 700; margin-left: 10px"
                                    >x {{ item.num }}</span
                                >
                            </div>
                            <div style="font-weight: 700; margin-left: 10px">
                                ￥{{ item.price_money }}
                            </div>
                        </div>
                        <div
                            style="border-top: 1px dashed #999; margin: 10px 0"
                        ></div>

                        <div style="color: #333">
                            <div
                                style="
                                    display: flex;
                                    justify-content: space-between;
                                "
                            >
                                <div>商品金额</div>
                                <div style="font-weight: 700">
                                    ￥{{
                                        (sumGodsMoney(item.goods_list) +
                                            parseFloat(item.discount_money))
                                            | numberToFixed
                                    }}
                                </div>
                            </div>
                            <div
                                v-if="item.discount_money > 0"
                                style="
                                    display: flex;
                                    justify-content: space-between;
                                "
                            >
                                <div>会员折扣</div>
                                <div style="font-weight: 700">
                                    -￥{{ item.discount_money | numberToFixed }}
                                </div>
                            </div>
                            <div
                                v-if="item.discount_money > 0"
                                style="
                                    display: flex;
                                    justify-content: space-between;
                                "
                            >
                                <div>总计</div>
                                <div style="font-weight: 700">
                                    ￥{{
                                        sumGodsMoney(item.goods_list)
                                            | numberToFixed
                                    }}
                                </div>
                            </div>

                            <div
                                v-if="item.remarks"
                                style="display: flex; margin-top: 5px"
                            >
                                <div style="width：100px">备注信息：</div>
                                <div style="flex: 1">{{ item.remarks }}</div>
                            </div>
                            <div
                                style="display: flex; justify-content: flex-end"
                            >
                                <div
                                    :style="
                                        info.pay_status == 1
                                            ? 'color:red'
                                            : 'color:#01B581'
                                    "
                                >
                                    {{
                                        info.pay_status == 1
                                            ? "未付款"
                                            : "已付款"
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="margin-top: 10px; line-height: 25px">
                    <div
                        class="order-title"
                        style="font-size: 16px; margin-bottom: 10px"
                    >
                        整单信息
                    </div>
                    <div
                        v-if="info.type == 1 && info.desk_num != 0"
                        style="display: flex; justify-content: space-between"
                    >
                        <div>
                            餐位费&nbsp;(&nbsp;{{
                                info.desk_num
                            }}位&nbsp;)&nbsp;
                        </div>
                        <div style="font-weight: 700">
                            ￥{{ info.seat_money | numberToFixed }}
                        </div>
                    </div>
                    <div
                        v-if="
                            info.type == 2 ||
                            (info.type == 3 && info.z_eat_type != 1)
                        "
                        style="display: flex; justify-content: space-between"
                    >
                        <div>打包费</div>
                        <div style="font-weight: 700">
                            ￥{{ sumTotalPackMoney | numberToFixed }}
                        </div>
                    </div>
                    <div style="display: flex; justify-content: space-between">
                        <div>商品总金额</div>
                        <div style="font-weight: 700">
                            ￥{{ sumTotalGodsMoney | numberToFixed }}
                        </div>
                    </div>
                    <div
                        v-if="info.type == 2"
                        style="display: flex; justify-content: space-between"
                    >
                        <div>配送费</div>
                        <div style="font-weight: 700">
                            ￥{{ info.delivery_money | numberToFixed }}
                        </div>
                    </div>
                    <!-- <div v-if="info.type==2" style="display:flex;justify-content: space-between;">
              <div>总单价</div>
              <div style="font-weight:700;">￥{{sumTotalGodsMoney - info.delivery_money | numberToFixed}}</div>
            </div> -->

                    <div
                        style="border-top: 1px dashed #999; margin: 10px 0"
                    ></div>
                    <div
                        v-if="info.change_money > 0"
                        style="display: flex; justify-content: space-between"
                    >
                        <div>收银改价优惠</div>
                        <div style="font-weight: 700">
                            -￥{{ info.change_money | numberToFixed }}
                        </div>
                    </div>
                    <div
                        v-if="info.order_discount_sum > 0"
                        style="display: flex; justify-content: space-between"
                    >
                        <div>会员总折扣</div>
                        <div style="font-weight: 700">
                            -￥{{ info.order_discount_sum | numberToFixed }}
                        </div>
                    </div>
                    <div
                        v-if="info.coupon_money > 0"
                        style="display: flex; justify-content: space-between"
                    >
                        <div>优惠券</div>
                        <div style="font-weight: 700">
                            -￥{{ info.coupon_money | numberToFixed }}
                        </div>
                    </div>
                    <div
                        v-if="info.dec_money > 0"
                        style="display: flex; justify-content: space-between"
                    >
                        <div>立减优惠</div>
                        <div style="font-weight: 700">
                            -￥{{ info.dec_money | numberToFixed }}
                        </div>
                    </div>

                    <div style="display: flex; justify-content: space-between">
                        <div>应付金额</div>
                        <div style="font-weight: 700">
                            ￥{{ info.payable_money | numberToFixed }}
                        </div>
                    </div>
                    <div style="display: flex; justify-content: space-between">
                        <div>已支付金额</div>
                        <div style="font-weight: 700">
                            ￥{{ info.order_pay_money | numberToFixed }}
                        </div>
                    </div>
                    <div
                        v-if="info.wx_pay_money > 0"
                        style="
                            display: flex;
                            justify-content: space-between;
                            font-size: 12px;
                        "
                    >
                        <div style="margin-left: 20px">微信支付</div>
                        <div style="font-weight: 700">
                            ￥{{ info.wx_pay_money | numberToFixed }}
                        </div>
                    </div>
                    <div
                        v-if="info.zfb_pay_money > 0"
                        style="
                            display: flex;
                            justify-content: space-between;
                            font-size: 12px;
                        "
                    >
                        <div style="margin-left: 20px">支付宝支付</div>
                        <div style="font-weight: 700">
                            ￥{{ info.zfb_pay_money | numberToFixed }}
                        </div>
                    </div>
                    <div
                        v-if="info.yue_pay_money > 0"
                        style="
                            display: flex;
                            justify-content: space-between;
                            font-size: 12px;
                        "
                    >
                        <div style="margin-left: 20px">余额支付</div>
                        <div style="font-weight: 700">
                            ￥{{ info.yue_pay_money | numberToFixed }}
                        </div>
                    </div>
                    <div
                        v-if="info.cash_pay_money > 0"
                        style="
                            display: flex;
                            justify-content: space-between;
                            font-size: 12px;
                        "
                    >
                        <div style="margin-left: 20px">现金支付</div>
                        <div style="font-weight: 700">
                            ￥{{ info.cash_pay_money | numberToFixed }}
                        </div>
                    </div>
                    <div
                        v-if="info.wait_money > 0"
                        style="
                            display: flex;
                            justify-content: space-between;
                            color: #ff0000;
                        "
                    >
                        <div>未支付金额</div>
                        <div style="font-weight: 700">
                            ￥{{ info.wait_money | numberToFixed }}
                        </div>
                    </div>
                    <div
                        v-if="info.wait_money == 0"
                        style="text-align: right; color: #01b581"
                    >
                        <div>已全部付款</div>
                    </div>
                </div>
            </div>

            <div
                class="content pop-btn"
                :style="{
                    'padding-top': '15px',
                    'border-top': '1px solid #eee',
                    'justify-content':
                        info.order_records.length > 0
                            ? 'space-between'
                            : 'flex-end',
                }"
            >
                <Button
                    class="btn-info"
                    @click="openRecord"
                    v-if="info.order_records.length > 0"
                    >订单日志</Button
                >
                <Button @click="closeModal">关闭</Button>
            </div>
        </div>
        <Modal
            v-if="info.order_records.length > 0"
            v-model="showRecord"
            title="订单日志"
            :width="600"
            :mask-closable="false"
            :z-index=105
        >
            <div style="display: flex; max-height: 500px; overflow-y: auto;">
                <div style="width: 50%">
                    <Timeline>
                        <TimelineItem
                            v-for="(item, index) in info.order_records"
                            :key="index"
                        >
                            <p class="time">{{ item.created_at }}</p>
                            <p class="content">
                                操作：{{ item.action }}<br />
                                终端：{{ item.terminal_type }}
                            </p>
                        </TimelineItem>
                    </Timeline>
                </div>
                <div style="width: 50%">
                    <Timeline>
                        <TimelineItem
                            v-for="(it, index) in info.print_log_list"
                            :key="index"
                        >
                            <p class="time">{{ it.created_at }}</p>
                            <p class="content">
                                打印机名称：{{
                                    it.type == 1 ? it.print_name : "USB打印机"
                                }}<br />
                                <p v-if="it.type == 1">
                                    打印机编号：{{ it.print_number }}
                                </p>
                                打印次数：{{ it.times }}<br />
                                状态：{{ it.status }}<br />
                                <p
                                    v-if="it.original_status == 2"
                                    style="color: #ff0000"
                                >
                                    原因：{{ it.result }}
                                </p>
                                <p
                                    @click="toShowPrint(it)"
                                    style="cursor: pointer; color: rgb(112 170 229)"
                                >
                                    {{
                                        it.order_type == 1 ? "总单内容" : "分单内容"
                                    }}
                                </p>
                            </p>
                        </TimelineItem>
                    </Timeline>
                </div>
            </div>

            <div slot="footer">
                <Button @click="showRecord = false">关闭</Button>
            </div>
        </Modal>
    </div>
</template>
<script>
import { recordDetails } from "@/api/order";
export default {
    name: "order_info",
    props: {
        // show : {
        //       type : Boolean,
        //       default : false
        //   },
        //   info : {
        //       type : Object,
        //       default : {}
        //   },
        order_id: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            info: {},
            showModal: false,
            showRecord: false,
        };
    },
    filters: {
        numberToFixed(v) {
            return parseFloat(v).toFixed(2);
        },
    },
    computed: {
        sumPackMoney(res) {
            return (res) => {
                return res.reduce(
                    (acc, cur) => acc + cur.num * cur.prices.pack_money,
                    0
                );
            };
        },

        sumGodsMoney(res) {
            return (res) => {
                return res.reduce(
                    (acc, cur) => acc + cur.num * cur.prices.price,
                    0
                );
            };
        },
        sumTotalGodsMoney() {
            let price =
                parseFloat(
                    this.info.goods.reduce(
                        (acc, cur) => acc + cur.num * cur.prices.price,
                        0
                    )
                ) + parseFloat(this.info.discount_money);
            let money = 0;
            if (this.info.branch.length > 0) {
                this.info.branch.forEach((res) => {
                    money +=
                        parseFloat(
                            res.goods_list.reduce(
                                (acc, cur) => acc + cur.num * cur.prices.price,
                                0
                            )
                        ) + parseFloat(res.discount_money);
                });
            }
            return (parseFloat(price) + parseFloat(money)).toFixed(2);
        },
        sumTotalPackMoney() {
            let price = this.info.goods.reduce(
                (acc, cur) => acc + cur.num * cur.prices.pack_money,
                0
            );
            let money = 0;
            if (this.info.branch.length > 0) {
                this.info.branch.forEach((res) => {
                    money += res.goods_list.reduce(
                        (acc, cur) => acc + cur.num * cur.prices.pack_money,
                        0
                    );
                });
            }
            return (parseFloat(price) + parseFloat(money)).toFixed(2);
        },
    },
    watch: {
        order_id(r, e) {
            console.log(r, e);
            if (r != e && r != 0) {
                this.loadList();
            }
        },
    },
    methods: {
        toShowPrint(e) {
            this.$Modal.confirm({
                maskClosable: false,
                content: e.content,
            });
        },
        loadList() {
            recordDetails({ id: this.order_id }).then((res) => {
                this.info = res.data;
                this.showModal = true;
            });
        },
        closeModal() {
            this.$emit("update:order_id", 0);
            this.showModal = false;
        },
        openRecord() {
            this.showRecord = true;
        },
    },
};
</script>
<style scoped>
.invite_span{
    color: black;
    font-weight: bold;
}
.pop {
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index:100;
    background: black;
    opacity: 0.3;
}
.pop-card {
    width: 500px;
    border-radius: 6px;
    background: white;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;
    padding: 20px 10px;
    box-sizing: border-box;
    color: #343434 !important;
}
.pop-card .title {
    font-size: 20px;
    font-weight: bold;
    color: black;
    text-align: center;
}
.pop-card .content {
    font-size: 15px;
}
.pop-card .close {
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 20px;
}
.shops {
    display: flex;
    font-size: 14px !important;
    flex-wrap: wrap;
}
.item-shops {
    width: 100%;
}
.pop-btn {
    display: flex;
}

.desk_number {
    text-align: center;
    color: #ff761d;
    font-size: 20px;
    padding: 0 10px 10px;
}

.status-pic {
    width: 90px;
    height: 61px;
    position: absolute;
    left: 60px;
}
.status-pic img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.order-title {
    font-weight: 600;
}
.order-title::before {
    width: 2px;
    height: 11px;
    background: #ff761d;
    margin-right: 5px;
    display: inline-block;
    content: " ";
    background-image: initial;
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: #ff761d;
}
.order-time {
    font-size: 12px;
}
.btn-info {
    height: 30px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ff5c11;
    color: #ff5c11;
    border-radius: 4px;
    cursor: pointer;
}
</style>