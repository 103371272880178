<template>
  <div class="son-page">
    <div class="add_box">
      <div class="addBox">
        <div><Button @click="addBtn('add')" class="btn">新增</Button></div>
        <div>
          <Icon
            size="20"
            style="color: #ff761d; margin-right: 10px"
            type="ios-alert"
          />第一条为系统默认会员信息不可删除
        </div>
      </div>
    </div>
    <div class="content_box">
      <Table ref="selection" stripe :columns="worlkColumns" :data="userdata">
        <template slot-scope="{ index }" slot="name">
          <div>{{ index + 1 }}</div>
        </template>
        <template slot-scope="{ row }" slot="giftbag">
          <Button v-if="row.type == 1" @click="setupBtn(row.id)">未设置</Button>
          <Button v-if="row.type == 0" @click="setupBtn(row.id)">已设置</Button>
        </template>
        <template slot-scope="{ row }" slot="level_img">
          <div @click="previewPic(row.new_level_img)" class="Table_pic">
            <img :src="row.new_level_img" alt="" />
          </div>
        </template>
        <template slot-scope="{ row, index }" slot="operation">
          <Button
            @click="addBtn(row)"
            type="info"
            ghost
            size="small"
            style="margin-right: 5px"
            >编辑</Button
          >
          <Button
            v-if="index !== 0"
            @click="delBtn(row.id)"
            type="error"
            ghost
            size="small"
            >删除</Button
          >
        </template>
      </Table>
      <br />
      <Page
        :total="total"
        :current="storePrams.page"
        style="text-align: right"
        show-elevator
        show-total
        @on-change="pageChange"
        :page-size="storePrams.limit"
      />
    </div>
    <Modal
      v-model="addModal"
      :mask-closable="false"
      :title="ModalTitle"
      @on-cancel="addModalcancel"
    >
      <Form style="padding: 0 20px" label-position="left" :label-width="90">
        <FormItem label="等级名称：">
          <Input
            placeholder="请输入等级名称"
            v-model="formdata.level_name"
          ></Input>
        </FormItem>
        <FormItem label="成长值：">
          <Input placeholder="请输入成长值" v-model="formdata.val"></Input>
        </FormItem>
        <FormItem label="等级折扣：">
          <Input
            placeholder="八折为8.0，八点五折为8.5"
            v-model="formdata.discount"
          ></Input>
        </FormItem>
        <FormItem label="会员icon：">
          <div style="display: flex">
            <div>
              <!-- <Upload
                    ref="upload"
                    :show-upload-list="false"
                    :format="['jpg','jpeg','png']"
                    :max-size="2048"
                    :headers="header"
                    :on-format-error="handleFormatError"
                    :on-success="uploadImgSuccessIcon"
                    :on-progress="handleProgress"
                    multiple
                    :action="uploads"> -->
              <div class="uplads_box" @click="openModal(1)">
                <div style="text-align: center">
                  <div style="margin-top: -10px">
                    <Icon style="color: #9ea6b9" size="70" type="ios-add" />
                  </div>
                  <div class="font14" style="margin-top: -10px; color: #9ca7b9">
                    点击上传
                  </div>
                  <div
                    style="font-size: 12px; color: #9ca7b9; margin-top: -15px"
                  >
                    （50*50）
                  </div>
                </div>
              </div>
              <!-- </Upload> -->
            </div>
            <div v-if="imgview_icon" class="img_view_Box">
              <img :src="imgview_icon" alt="" />
            </div>
          </div>
        </FormItem>
        <FormItem label="会员卡图：">
          <div style="display: flex">
            <div>
              <!-- <Upload
                ref="upload"
                :show-upload-list="false"
                :format="['jpg', 'jpeg', 'png']"
                :max-size="2048"
                :headers="header"
                :on-format-error="handleFormatError"
                :on-success="uploadImgSuccess"
                :on-progress="handleProgress"
                multiple
                :action="uploads"
              > -->
              <div class="uplads_box" @click="openModal(3)">
                <div style="text-align: center">
                  <div style="margin-top: -10px">
                    <Icon style="color: #9ea6b9" size="70" type="ios-add" />
                  </div>
                  <div class="font14" style="margin-top: -10px; color: #9ca7b9">
                    点击上传
                  </div>
                  <div
                    style="font-size: 12px; color: #9ca7b9; margin-top: -15px"
                  >
                    （337*181）
                  </div>
                </div>
              </div>
              <!-- </Upload> -->
            </div>
            <div v-if="imgview" class="img_view_Box">
              <img :src="imgview" alt="" />
            </div>
          </div>
        </FormItem>
      </Form>
      <imageLibrary
        v-if="switch_modal"
        :switch_modal.sync="switch_modal"
        @change="costPlannedAmountChange"
        :select="imgtype"
      ></imageLibrary>
      <div slot="footer">
        <Button @click="addModalcancel">取消</Button>
        <Button @click="addModalSub" class="btn">保存</Button>
      </div>
    </Modal>
    <Modal v-model="previewModal" :mask-closable="false" title="会员卡图">
      <img style="width: 100%" :src="pic" alt="" />
      <div slot="footer">
        <Button @click="previewModal = false">关闭</Button>
      </div>
    </Modal>
    <Modal
      v-model="GiftbagModal"
      :mask-closable="false"
      width="900"
      title="升级礼包设置"
    >
      <div style="margin: 0 0 20px 0">
        <Button @click="Giftbagadd('add')" class="btn">新增</Button>
      </div>
      <Table ref="selection" stripe :columns="GiftbagColumns" :data="data5">
        <template slot-scope="{ index }" slot="name">
          <div>{{ index + 1 }}</div>
        </template>
        <template slot-scope="{ row }" slot="created_at">
          <div v-if="row.term_type == 2">{{ row.term_validity }}天</div>
          <div v-if="row.term_type == 1">{{ row.term_validity }}</div>
          <div v-if="row.term_type == 3">{{ row.term_validity }}</div>
        </template>
        <template slot-scope="{ row }" slot="operation">
          <Button
            @click="Giftbagadd(row)"
            type="info"
            ghost
            size="small"
            style="margin-right: 5px"
            >编辑</Button
          >
          <Button @click="delleverBtn(row.id)" type="error" ghost size="small"
            >删除</Button
          >
        </template>
      </Table>
      <br />
      <Page
        :total="total1"
        :current="storePrams1.page"
        style="text-align: right"
        show-elevator
        show-total
        @on-change="pageChange1"
        :page-size="storePrams1.limit"
      />
      <div slot="footer">
        <Button @click="GiftbagModal = false">关闭</Button>
      </div>
    </Modal>
    <Modal
      v-model="CardrollModal"
      :mask-closable="false"
      :title="GiftbagTitle"
      @on-cancel="Cardrollcancel"
    >
      <Form style="padding: 0 20px" label-position="left" :label-width="90">
        <FormItem label="卡券名称：">
          <Select
            :disabled="GiftbagTitle == '新增礼包' ? false : true"
            v-model="formdata1.coupon_id"
          >
            <Option v-for="item in cityList" :value="item.id" :key="item.id">{{
              item.coupon_name
            }}</Option>
          </Select>
        </FormItem>
        <FormItem label="赠送数量：">
          <Input
            type="number"
            v-model="formdata1.num"
            placeholder="请输入赠送数量"
          ></Input>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="Cardrollcancel">取消</Button>
        <Button @click="CardrollSub" class="btn">保存</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  indexLevel,
  addLevel,
  editLevel,
  delLevel,
  indexLevelGift,
  coupon_ls,
  addLevelGift,
  delLevelGift,
  editLevelGift,
} from "@/api/user";
import config from "@/config";
import { getCookies } from "@/utils/helper";
import imageLibrary from "@/components/diy/imageLibrary.vue";
export default {
  components: { imageLibrary },
  name: "canteen",
  data() {
    return {
      imgtype: 1,
      switch_modal: false,
      header: {
        "hlzw-canyin-authori-zation": getCookies("hlzw_canyin_token"),
      },
      uploads: config.apiUrl + "/storeapi/data/uploads",
      imgview: "",
      imgview_icon: "",
      formdata: {
        level_name: "",
        level_icon: "",
        val: "",
        discount: "",
        level_img: "",
      },
      formdata1: {
        level_id: null,
        coupon_id: "",
        num: "",
      },
      pic: "",
      ModalTitle: "新增会员",
      GiftbagTitle: "新增礼包",
      addModal: false,
      previewModal: false,
      GiftbagModal: false,
      CardrollModal: false,
      total: 0,
      storePrams: {
        page: 1,
        limit: 10,
      },
      total1: 0,
      storePrams1: {
        level_id: null,
        page: 1,
        limit: 10,
      },
      data5: [],
      userdata: [],
      worlkColumns: [
        {
          title: "#",
          slot: "name",
          align: "center",
        },
        {
          title: "等级名称",
          key: "level_name",
          align: "center",
        },
        {
          title: "成长值",
          key: "val",
          align: "center",
        },
        {
          title: "等级折扣",
          key: "discount",
          align: "center",
        },
        {
          title: "升级礼包",
          slot: "giftbag",
          align: "center",
        },
        {
          title: "会员卡图",
          slot: "level_img",
          width: 180,
          align: "center",
        },
        {
          title: "操作",
          key: "action",
          width: 160,
          fixed: "right",
          align: "center",
          slot: "operation",
        },
      ],
      GiftbagColumns: [
        {
          title: "#",
          slot: "name",
          align: "center",
          width: 80,
        },
        {
          title: "卡卷名称",
          key: "coupon_name",
          align: "center",
        },
        {
          title: "数量",
          key: "num",
          align: "center",
          width: 160,
        },
        {
          title: "有效期",
          slot: "created_at",
          align: "center",
        },
        {
          title: "操作",
          key: "action",
          width: 160,
          fixed: "right",
          align: "center",
          slot: "operation",
        },
      ],
      cityList: [],
    };
  },
  created() {
    this.getList();
    this.coupon_ls();
  },
  methods: {
    // 事件处理函数
    async costPlannedAmountChange(param1) {
      if (this.imgtype == 1) {
        for (var i = 0; i < param1.length; i++) {
          this.imgview_icon = param1[i];
          this.formdata.level_icon = param1[i];
        }
        this.$Message.destroy();
      } else {
        for (var i = 0; i < param1.length; i++) {
          this.imgview = param1[i];
          this.formdata.level_img = param1[i];
        }
        this.$Message.destroy();
      }
    },
    openModal(e) {
      this.imgtype = e;
      this.switch_modal = true;
    },
    getList() {
      indexLevel(this.storePrams).then((res) => {
        this.total = res.data.total;
        this.userdata = res.data.data;
      });
    },
    coupon_ls() {
      coupon_ls().then((res) => {
        this.cityList = res.data;
      });
    },
    indexLevelGift() {
      indexLevelGift(this.storePrams1).then((res) => {
        this.total1 = res.data.total;
        this.data5 = res.data.data;
      });
    },
    previewPic(e) {
      this.pic = e;
      this.previewModal = true;
    },
    addBtn(e) {
      if (e == "add") {
        this.ModalTitle = "新增会员";
        this.addModal = true;
      } else {
        this.imgview = e.new_level_img;
        this.imgview_icon = e.new_level_icon;
        this.formdata.id = e.id;
        this.formdata.level_name = e.level_name;
        this.formdata.val = e.val;
        this.formdata.discount = e.discount;
        this.formdata.level_img = e.level_img;
        this.formdata.level_icon = e.level_icon;
        this.ModalTitle = "编辑会员";
        this.addModal = true;
      }
    },
    delBtn(id) {
      this.$Modal.confirm({
        title: "提示",
        content: `您正在使用删除功能`,
        onOk: () => {
          delLevel({ id: id })
            .then((res) => {
              this.$Message.success(res.msg);
              this.getList();
            })
            .catch((err) => {
              this.$Message.error(err.msg);
            });
        },
        onCancel: () => {},
      });
    },
    delleverBtn(id) {
      this.$Modal.confirm({
        title: "提示",
        content: `您正在使用删除功能`,
        onOk: () => {
          delLevelGift({ id: id })
            .then((res) => {
              this.$Message.success(res.msg);
              this.indexLevelGift();
            })
            .catch((err) => {
              this.$Message.error(err.msg);
            });
        },
        onCancel: () => {},
      });
    },
    addModalSub() {
      // if (this.formdata.level_icon.indexOf("https") >= 0) {
      //     const value1 = this.formdata.level_icon.slice(41);
      //     this.formdata.level_icon = value1;
      // }
      // if (this.formdata.level_icon.indexOf("http") >= 0) {
      //     const value2 = this.formdata.level_icon.slice(39);
      //     this.formdata.level_icon = value2;
      // }
      // if (this.formdata.level_img.indexOf("https") >= 0) {
      //     const value1 = this.formdata.level_img.slice(41);
      //     this.formdata.level_img = value1;
      // }
      // if (this.formdata.level_img.indexOf("http") >= 0) {
      //     const value2 = this.formdata.level_img.slice(39);
      //     this.formdata.level_img = value2;
      // }
      if (this.ModalTitle == "新增会员") {
        addLevel(this.formdata)
          .then((res) => {
            this.$Message.success(res.msg);
            this.imgview = "";
            this.imgview_icon = "";
            (this.formdata = {
              level_name: "",
              level_icon: "",
              val: "",
              discount: "",
              level_img: "",
            }),
              this.getList();
            this.addModal = false;
          })
          .catch((err) => {
            this.$Message.error(err.msg);
          });
      } else {
        editLevel(this.formdata)
          .then((res) => {
            this.$Message.success(res.msg);
            this.imgview = "";
            this.imgview_icon = "";
            (this.formdata = {
              level_name: "",
              level_icon: "",
              val: "",
              discount: "",
              level_img: "",
            }),
              this.getList();
            this.addModal = false;
          })
          .catch((err) => {
            this.$Message.error(err.msg);
          });
        this.addModal = false;
      }
    },
    addModalcancel() {
      this.imgview = "";
      this.imgview_icon = "";
      this.formdata = {
        level_name: "",
        level_icon: "",
        val: "",
        discount: "",
        level_img: "",
      };
      this.addModal = false;
    },
    setupBtn(id) {
      this.formdata1.level_id = id;
      this.storePrams1.level_id = id;
      this.indexLevelGift();
      this.GiftbagModal = true;
    },
    Giftbagadd(e) {
      if (e == "add") {
        this.GiftbagTitle = "新增礼包";
        this.CardrollModal = true;
      } else {
        this.formdata1.id = e.id;
        this.formdata1.coupon_id = e.coupon_id;
        this.formdata1.num = e.num;
        this.GiftbagTitle = "编辑卡卷";
        this.CardrollModal = true;
      }
    },
    CardrollSub() {
      if (this.GiftbagTitle == "新增礼包") {
        addLevelGift(this.formdata1)
          .then((res) => {
            this.$Message.success(res.msg);
            this.indexLevelGift();
            this.CardrollModal = false;
            this.clearform1();
          })
          .catch((err) => {
            this.$Message.error(err.msg);
          });
      } else {
        editLevelGift(this.formdata1)
          .then((res) => {
            this.$Message.success(res.msg);
            this.indexLevelGift();
            this.CardrollModal = false;
            this.clearform1();
          })
          .catch((err) => {
            this.$Message.error(err.msg);
          });
      }
    },
    clearform1() {
      this.formdata1.coupon_id = "";
      this.formdata1.num = "";
    },
    Cardrollcancel() {
      this.CardrollModal = false;
      // console.log('取消');
    },
    //文件上传类型错误
    handleFormatError() {
      this.$Message.warning("暂不支持上传此格式");
    },
    //编辑图片上传图片成功
    uploadImgSuccess(e) {
      this.imgview = e.data.path;
      this.formdata.level_img = e.data.src;
      this.$Message.destroy();
      this.$Message.success(e.msg);
    },
    uploadImgSuccessIcon(e) {
      this.imgview_icon = e.data.path;
      this.formdata.level_icon = e.data.src;
      this.$Message.destroy();
      this.$Message.success(e.msg);
    },
    // 文件上传时
    handleProgress() {
      const msg = this.$Message.loading({
        content: "上传中...",
        duration: 0,
      });
      setTimeout(msg, 3000);
    },
    pageChange(index) {
      this.storePrams.page = index;
      this.getList();
    },
    pageChange1(index) {
      this.storePrams1.page = index;
      this.getList();
    },
  },
};
</script>

<style scoped>
.son-page {
}
.add_box {
  width: 100%;
  background: #fff;
  padding: 20px 30px;
  border-radius: 12px;
}
.content_box {
  width: 100%;
  margin-top: 30px;
  background: #fff;
  padding: 20px 30px;
  border-radius: 12px;
}
.img_view_Box {
  width: 105px;
  height: 105px;
  border-radius: 5px;
  border: 1px solid #999;
  margin-left: 20px;
  overflow: hidden;
}
.img_view_Box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.btn {
  background: #ff761d;
  color: #fff;
  border-color: #ff761d;
}
.addBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Table_pic {
  width: 130px;
  height: 58px;
  border-radius: 5px;
  margin: 10px auto;
  overflow: hidden;
}
.Table_pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.uplads_box {
  width: 105px;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #a0a4bd;
  background: #f7f8fa;
  border-radius: 5px;
  cursor: pointer;
}
</style>
