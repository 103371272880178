<template>
  <div class="son-page">
    <div class="content_box">
      <div class="left_box">
        <div class="yuan_FF761D DW_nei_txt">审核设置</div>
        <div style="width:40%;margin:10px 0">
          <Form ref="formInline"  :label-width="120">
            <FormItem label="启用审核：" >
              <i-switch size="large" v-model="form_examine.is_examine" :true-value="1" :false-value="0">
                  <span slot="open">开启</span>
                  <span slot="close">关闭</span>
              </i-switch>
              <Tooltip style="cursor: pointer;" max-width="500" content="开启后，用户注册必须通过审核才能正常下单" placement="top">
                            <Icon style="margin-left:10px" size="20" type="ios-help-circle" />
                        </Tooltip>
          </FormItem>
          <FormItem label="必填项：" v-if="form_examine.is_examine == 1" style="margin:5px 0">
              <CheckboxGroup v-model="form_examine.examines" >
                  <Checkbox :label="1">姓名</Checkbox>
                  <Checkbox :label="2">手机</Checkbox>
                  <Checkbox :label="3">申请原因</Checkbox>
              </CheckboxGroup>
          </FormItem>
        </Form>
        </div>
        <div class="btns_box">
          <Button @click="saveExamineSetting" class="btn">保存</Button>
        </div>
      </div>
      
    </div>
    <div class="table_box">
      <div class="yuan_FF761D DW_nei_txt">申请记录</div>
      <Table ref="selection" stripe :columns="worlkColumns" :data="data6">
        <template slot-scope="{ index }" slot="name">
          <div>{{ index + 1 }}</div>
        </template>
        <template slot-scope="{ row }" slot="status">
          <div v-if="row.status==0" style="color:#FF9900">待审核</div>
          <div v-if="row.status==1" style="color:#19BE6B">同意</div>
          <div v-if="row.status==2" style="color:red">拒绝</div>
        </template>
        <template slot-scope="{ row }" v-if="row.status == 0" slot="operation">
          <Button @click="saveExamine(row, 1)" type="info" ghost size="small" style="margin-right: 5px">同意</Button>
          <Button @click="saveExamine(row, 2)" type="error" ghost size="small" style="margin-right: 5px">拒绝</Button>
        </template>
        <template slot-scope="{ row }" slot="refuse">
          <textarea v-model="row.refuse_text"></textarea>
        </template>
        <template slot-scope="{ row }" slot="apply">
          <textarea v-model="row.apply_desc"></textarea>
        </template>

        
      </Table>
      <br/>
      <Page :total="total"
            :current="storePrams.page"
            style="text-align:right"
            show-elevator
            show-total
            @on-change="pageChange"
            :page-size="storePrams.limit"/>
    </div>
   
    <Modal
        v-model="withdrawalmodal"
        title="提现"
        width="500">
    </Modal>
    
  </div>
</template>

<script>
import {saveExamineSetting, getExamineSetting, getUserExamineList, saveUserExamineStatus} from '@/api/user'
export default {
  name: "canteen",
  data(){
    return{
      refuse:{
          id:'',
          status:0,
          refuse_text:"",
      },
      form_examine:{
        is_examine:0,
        examines:[]
      },
      withdrawalmodal:false,
      data6:[],
      total:0,
      storePrams:{
        page:1,
        limit:10
      },
      worlkColumns:[
        {
          title: '#',
          slot: 'name',
          align: 'center'
        },
        {
          title: '姓名',
          key: 'name',
          align: 'center'
        },
        {
          title: '手机',
          key: 'phone',
          align: 'center'
        },
        {
          title: '申请原因',
          slot: 'apply',
          align: 'center'
        },
        {
          title: '申请时间',
          key: 'created_at',
          align: 'center'
        },
        {
          title: '申请状态',
          slot: 'status',
          width: 180,
          align: 'center'
        },
        {
          title: '拒绝原因',
          slot: 'refuse',
          width: 180,
          align: 'center'
        },
        {
          title: '操作',
          key: 'action',
          width: 160,
          fixed: 'right',
          align: 'center',
          slot: 'operation'
        }
      ]
    }
  },
  created() {
    this.getExamine()
    this.getUserExamineList()
  },
  methods:{
    getUserExamineList(){
      getUserExamineList(this.storePrams).then(res=>{
        this.data6 = res.data.data
        this.total = res.data.total
      }).catch(err=>{
        console.log('getUserExamineList', err)
      })
    },
    getExamine(){
      getExamineSetting().then(res=>{
        this.form_examine = res.data
          console.log(res)
      }).catch(err=>{
          console.log('getExamineSetting', err)
      })
    },
    saveExamineSetting(){
       if ( this.form_examine.is_examine == 1 && this.form_examine.examines.length == 0 ) {
            return this.$Message.warning('必须选择一个必选项');
       }
       saveExamineSetting(this.form_examine).then(res=>{
          if(res.code == 200) {
             this.$Message.success(res.msg);
          } else {
             this.$Message.warning(res.msg);
          }

      }).catch(err=>{
          console.log('saveExamine', err)
      })
    },
    pageChange(index){
      this.storePrams.page = index
      this.getUserExamineList()
    },
    saveExamine(e, status){
      this.refuse.id  = e.id
      this.refuse.status = status
      if (status == 2) {
        this.$Modal.confirm({
            render: (h) => {
                return h('Input', {
                    props: {
                        autofocus: true,
                        placeholder: '请输入拒绝原因'
                    },
                    on: {
                        input: (val) => {
                          this.refuse.refuse_text = val;
                        }
                    }
                })
            },
            onOk: () => {
                this.submitDo()
            },
            onCancel: () => {
                this.$Message.info('Clicked cancel');
            }
        })
      } else {
        this.submitDo()
      }
    },
    submitDo(){
      saveUserExamineStatus(this.refuse).then(res=>{
        if (res.code == 200) {
          this.$Message.success(res.msg);
          this.getUserExamineList()
        } else {
          this.$Message.warning(res.msg);
        }
        
      }).catch(err=>{
        console.log('saveUserExamineStatus', err)
      })
    }
  }
}
</script>

<style scoped>
/* .son-page {

} */
.content_box{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.left_box{
  width: 100%;
  padding: 20px 30px;
  border-radius: 12px;
  background: #FFF;
}
.mb15{
  margin-bottom: 15px!important;
}
.yuan_FF761D::before{
  content: ' ';
  display: block;
  width: 2px;
  height: 16px;
  margin-right: 10px;
  background: #FF761D;
}
.DW_nei_txt{
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  margin-bottom:10px;
}
.btn{
  background:#FF761D;
  color:#FFF;
  border-color: #FF761D;
}
.btns_box{
  display: flex;
  justify-content: space-between;
}
.img_box{
  width: 100px;
  height: 100px;
  margin-left: -100px;
  border: 1px solid #999;
  border-radius: 5px;
  object-fit: cover;
}
.uplads_box{
    width: 105px;
    height: 105px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #A0A4BD;
    background: #F7F8FA;
    border-radius: 5px;
    cursor: pointer;
}
.fenbu{
  display: flex;
  align-items: center;
  height: 100px;
  /* justify-content: space-between; */
}
.img_view_Box{
  width: 105px;
  height: 105px;
  border-radius: 5px;
  border: 1px solid #999;
  margin-left: 20px;
  overflow: hidden;
}
.img_view_Box img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ivu-form-item{
  margin-bottom: 0;
  margin-right: 70px;
}
.end_flex{
  display: flex;
  justify-content: flex-end;
}
.table_box{
  width: 100%;
  margin-top: 30px;
  background: #FFF;
  border-radius: 12px;
  padding: 20px 30px;
}
.txt_FF761D{
  color: #FF761D;
}
.yiti{
  margin-left: 10px;
  padding: 20px 0;
}
</style>
