<template>
  <div class="son-page">
    <div class="content_box">
      <div class="tishi_box"><Icon size="20" style="color:#FF761D;margin-right:10px" type="ios-alert" />开启后，用户登录时候需要先绑定手机号，姓名，才能进行下一步操作</div>
      <div class="user_box">
        <Form label-position="left" :label-width="120">
            <FormItem label="手机号码绑定：">
                <i-switch @on-change="check" v-model="formdata.phone_bind" :true-value="1" :false-value="0"/>
            </FormItem>
            <FormItem label="用户姓名绑定：">
                <i-switch @on-change="check1" v-model="formdata.name_bind" :true-value="1" :false-value="0"/>
            </FormItem>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import {getUserUp,userUp} from '@/api/user'
export default {
  name: "canteen",
  data(){
    return{
      formdata:{
        phone_bind:0,
        name_bind:0
      },
    }
  },
  created() {
    this.getUserUp()
  },
  methods:{
    getUserUp(){
      getUserUp().then(res=>{
        this.formdata.phone_bind = res.data.phone_bind
        this.formdata.name_bind = res.data.name_bind
      })
    },
    check(){
      userUp(this.formdata).then(res=>{
        this.$Message.success(res.msg)
      }).catch(err=>{
        this.$Message.error(err.msg)
      })
    },
    check1(){
      userUp(this.formdata).then(res=>{
        this.$Message.success(res.msg)
      }).catch(err=>{
        this.$Message.error(err.msg)
      })
    },
  }
}
</script>

<style scoped>
.son-page {

}
.content_box{
  width: 100%;
  background: #FFF;
  height: 84vh;
  border-radius: 12px;
}
.tishi_box{
  background: #FFF1E8;
  text-align: center;
  line-height: 50px;
}
.user_box{
  padding: 20px 30px;
}
.ivu-switch-checked{
  border-color: #FF761D;
  background-color: #FF761D;
}
</style>
