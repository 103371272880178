var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-style"},[_c('div',{staticClass:"left-menu"},_vm._l((_vm.leftMenu),function(item,index){return _c('div',{key:index},[(item.children && item.children.length != 0)?_c('div',[_c('div',{staticClass:"menu-nav",class:item.show ? 'active1' : '',on:{"click":function($event){return _vm.changeLabel(item)}}},[_c('Icon',{staticStyle:{"margin-right":"10px"},attrs:{"type":item.icon,"size":"24"}}),_vm._v(" "+_vm._s(item.title)+" "),(item.show)?_c('Icon',{attrs:{"type":"ios-arrow-down"}}):_c('Icon',{attrs:{"type":"ios-arrow-forward"}})],1),(item.show)?_c('div',_vm._l((item.children),function(j,k){return _c('div',{key:k},[_c('div',{staticClass:"menu-nav",class:_vm.chilIndex === j.src ? 'active' : '',on:{"click":function($event){return _vm.chilLabel(j)}}},[_c('span',{staticClass:"hezi"}),_vm._v(" "+_vm._s(j.title)+" "),(j.children && j.children.length != 0)?_c('Icon',{attrs:{"type":"ios-arrow-down"}}):_vm._e()],1)])}),0):_vm._e()]):_c('div',[(
                        (item.id == 63 &&
                            _vm.$store.state.userInfo.data_id == 676) ||
                        (item.id == 63 &&
                            _vm.$store.state.userInfo.data_id == 903) ||
                        (item.id == 63 &&
                            _vm.$store.state.userInfo.data_id == 1195) ||
                        item.id != 63
                    )?_c('div',{staticClass:"menu-nav",class:_vm.activeIndex === item.src ? 'active' : '',on:{"click":function($event){return _vm.changeLabel(item)}}},[_c('Icon',{staticStyle:{"margin-right":"10px"},attrs:{"type":item.icon,"size":"24"}}),_vm._v(" "+_vm._s(item.title)+" ")],1):_vm._e()])])}),0),_c('div',{staticClass:"right-content"},[_c('div',{staticClass:"content-del"},[(_vm.activeIndex === '/admin/user/overview')?_c('div',[_c('Canteen')],1):_vm._e(),(_vm.activeIndex === '/admin/user/userlist')?_c('div',[_c('Takeout')],1):_vm._e(),(_vm.activeIndex === '/admin/user/usersettings')?_c('div',[_c('Usersettings')],1):_vm._e(),(_vm.activeIndex === '/admin/user/examine')?_c('div',[_c('Examine')],1):_vm._e(),(_vm.activeIndex === '/admin/user/timedschedule')?_c('div',[_c('Timedschedule')],1):_vm._e(),(_vm.chilIndex === '/admin/user/level')?_c('div',[_c('Membershiplevel')],1):_vm._e(),(_vm.chilIndex === '/admin/user/growthvaluerule')?_c('div',[_c('Growthvalue')],1):_vm._e(),(_vm.chilIndex === '/admin/user/cardopening')?_c('div',[_c('Bankcard')],1):_vm._e(),(_vm.chilIndex === '/admin/user/notes')?_c('div',[_c('Notice')],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }