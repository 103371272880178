<template>
    <div  :id="id" :style="'height:'+height+'px;width:'+width"></div>
</template>
<script>
import * as echarts from 'echarts';
export default {
    name:"echartLine",
    data(){
        return{
        }
    },
    props:{
        id:{
            type:String,
            default:'line'
        },
        width:{
            type:String,
            default:'100%'
        },
        height:{
            type:String, 
            default:'400'
        },
        xAxis:{
            type:Array,
            default:function(){
                return ['周一', '周二', '周三', '周四', '周五', '周六', '周日'];
            }
        },
        series:{
            type:Array,
            default: function(){
                return [{
                    type:'line',
                    data:[150, 230, 224, 218, 135, 147, 260]
                }]
            }
        },
        title:{
            type:String,
            default: '标题'
        },
        legend:{
            type:Object,
            default:function(){
                return {}
            }
        },
        titleCenter:{
            type:String,
            default: 'center'
        }
    },
    mounted(){
        this.initChat();
    },
    methods:{
        initChat(){
            var chartDom = document.getElementById(this.id);
            var myChart = echarts.init(chartDom);
            var option;

            option = {
                // title: {
                //     text: this.title,
                //     left: this.titleCenter
                // },
                tooltip: {
                    trigger: 'axis'
                },
                xAxis: {
                    type: 'category',
                    data:this.xAxis
                },
                legend: this.legend,
                yAxis: {
                    type: 'value'
                },
                series: this.series,
                grid: {

                    x: 50,

                    y: 50,

                    x2: 30,

                    y2: 50

                }
            };

            option && myChart.setOption(option);
            window.onresize = function () {
                myChart.resize();
            }

        }
    }
}
</script>