var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"son-page"},[_c('div',{staticClass:"content_box"},[_c('div',{staticClass:"left_box"},[_c('div',{staticClass:"yuan_FF761D DW_nei_txt"},[_vm._v("审核设置")]),_c('div',{staticStyle:{"width":"40%","margin":"10px 0"}},[_c('Form',{ref:"formInline",attrs:{"label-width":120}},[_c('FormItem',{attrs:{"label":"启用审核："}},[_c('i-switch',{attrs:{"size":"large","true-value":1,"false-value":0},model:{value:(_vm.form_examine.is_examine),callback:function ($$v) {_vm.$set(_vm.form_examine, "is_examine", $$v)},expression:"form_examine.is_examine"}},[_c('span',{attrs:{"slot":"open"},slot:"open"},[_vm._v("开启")]),_c('span',{attrs:{"slot":"close"},slot:"close"},[_vm._v("关闭")])]),_c('Tooltip',{staticStyle:{"cursor":"pointer"},attrs:{"max-width":"500","content":"开启后，用户注册必须通过审核才能正常下单","placement":"top"}},[_c('Icon',{staticStyle:{"margin-left":"10px"},attrs:{"size":"20","type":"ios-help-circle"}})],1)],1),(_vm.form_examine.is_examine == 1)?_c('FormItem',{staticStyle:{"margin":"5px 0"},attrs:{"label":"必填项："}},[_c('CheckboxGroup',{model:{value:(_vm.form_examine.examines),callback:function ($$v) {_vm.$set(_vm.form_examine, "examines", $$v)},expression:"form_examine.examines"}},[_c('Checkbox',{attrs:{"label":1}},[_vm._v("姓名")]),_c('Checkbox',{attrs:{"label":2}},[_vm._v("手机")]),_c('Checkbox',{attrs:{"label":3}},[_vm._v("申请原因")])],1)],1):_vm._e()],1)],1),_c('div',{staticClass:"btns_box"},[_c('Button',{staticClass:"btn",on:{"click":_vm.saveExamineSetting}},[_vm._v("保存")])],1)])]),_c('div',{staticClass:"table_box"},[_c('div',{staticClass:"yuan_FF761D DW_nei_txt"},[_vm._v("申请记录")]),_c('Table',{ref:"selection",attrs:{"stripe":"","columns":_vm.worlkColumns,"data":_vm.data6},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var index = ref.index;
return [_c('div',[_vm._v(_vm._s(index + 1))])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [(row.status==0)?_c('div',{staticStyle:{"color":"#FF9900"}},[_vm._v("待审核")]):_vm._e(),(row.status==1)?_c('div',{staticStyle:{"color":"#19BE6B"}},[_vm._v("同意")]):_vm._e(),(row.status==2)?_c('div',{staticStyle:{"color":"red"}},[_vm._v("拒绝")]):_vm._e()]}},{key:"operation",fn:function(ref){
var row = ref.row;
return (row.status == 0)?[_c('Button',{staticStyle:{"margin-right":"5px"},attrs:{"type":"info","ghost":"","size":"small"},on:{"click":function($event){return _vm.saveExamine(row, 1)}}},[_vm._v("同意")]),_c('Button',{staticStyle:{"margin-right":"5px"},attrs:{"type":"error","ghost":"","size":"small"},on:{"click":function($event){return _vm.saveExamine(row, 2)}}},[_vm._v("拒绝")])]:undefined}},{key:"refuse",fn:function(ref){
var row = ref.row;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(row.refuse_text),expression:"row.refuse_text"}],domProps:{"value":(row.refuse_text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(row, "refuse_text", $event.target.value)}}})]}},{key:"apply",fn:function(ref){
var row = ref.row;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(row.apply_desc),expression:"row.apply_desc"}],domProps:{"value":(row.apply_desc)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(row, "apply_desc", $event.target.value)}}})]}}],null,true)}),_c('br'),_c('Page',{staticStyle:{"text-align":"right"},attrs:{"total":_vm.total,"current":_vm.storePrams.page,"show-elevator":"","show-total":"","page-size":_vm.storePrams.limit},on:{"on-change":_vm.pageChange}})],1),_c('Modal',{attrs:{"title":"提现","width":"500"},model:{value:(_vm.withdrawalmodal),callback:function ($$v) {_vm.withdrawalmodal=$$v},expression:"withdrawalmodal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }