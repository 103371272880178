<template>
  <div class="son-page">
    <div class="content_box">
      <Form label-position="left" :label-width="180">
          <FormItem label="每充值1元增加：">
            <div class="center">
              <Input v-model="formdata.grow_recharge" style="width:200px"></Input>
              <div class="txt">成长值</div>
            </div>
          </FormItem>
          <FormItem label="微信每次付款增加1元：">
            <div class="center">
              <Input v-model="formdata.grow_wx_pay" style="width:200px"></Input>
              <div class="txt">成长值</div>
            </div>
          </FormItem>
          <FormItem label="支付宝每次付款1元增加：">
            <div class="center">
              <Input v-model="formdata.grow_zfb_pay" style="width:200px"></Input>
              <div class="txt">成长值</div>
            </div>
          </FormItem>
          <FormItem label="余额每次付款1元增加：">
            <div class="center">
              <Input v-model="formdata.grow_yue_pay" style="width:200px"></Input>
              <div class="txt">成长值</div>
            </div>
          </FormItem>
      </Form>
      <div class="bottom_box"><Button @click="addBtn" class="btn">保存</Button></div>
    </div>
  </div>
</template>

<script>
import {growthValueInfo,growthValue} from '@/api/user'
export default {
  name: "canteen",
  data(){
    return{
      formdata:{
        grow_recharge:'',
        grow_wx_pay:'',
        grow_zfb_pay:'',
        grow_yue_pay:''
      }
    }
  },
  created() {
    this.growthValueInfo()
  },
  methods:{
    growthValueInfo(){
      growthValueInfo().then(res=>{
        this.formdata = res.data
      })
    },
    addBtn(){
      growthValue(this.formdata).then(res=>{
        this.$Message.success(res.msg)
      }).catch(err=>{
        this.$Message.error(err.msg)
      })
    },
  }
}
</script>

<style scoped>
.son-page {

}
.content_box{
  position: relative;
  width: 100%;
  background: #FFF;
  height: 88vh;
  padding: 20px 30px;
  border-radius: 12px;
}
.bottom_box{
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #eee;
}
.btn{
  background:#FF761D;
  color:#FFF;
  border-color: #FF761D;
}
.center{
  display: flex;
}
.txt{
  width: 80px;
  text-align: center;
}
</style>
