<template>
  <div class="son-page">
    <div class="content_box">
      <Form label-position="left" :label-width="100">
        <FormItem label="启用会员卡：">
          <i-switch v-model="formdata.card_is_enable" :true-value="1" :false-value="0"/>
        </FormItem>
        <span v-if="formdata.card_is_enable==1">
          <FormItem label="开卡场景：">
            <RadioGroup v-model="formdata.card_scene">
                <Radio :label="1">首页开卡</Radio>
                <Radio class="ml20" :label="2">自动开卡</Radio>
                <Radio class="ml20" :label="3">充值开卡</Radio>
            </RadioGroup>
          </FormItem>
          <FormItem v-if="formdata.card_scene==1" label="强制开卡：">
            <i-switch v-model="formdata.card_is_force" :true-value="1" :false-value="2"/>
          </FormItem>
          <FormItem label="初始等级：">
            <Select v-model="formdata.card_level_id" style="width:200px">
                <Option v-for="item in Levellist" :value="item.id" :key="item.id">{{ item.level_name }}</Option>
            </Select>
          </FormItem>
          <FormItem v-if="formdata.card_scene==1" label="付费开卡：">
            <Input v-model="formdata.card_pay_money" style="width:200px"></Input><span class="ml20">0则免费开卡</span>
          </FormItem>
          <FormItem v-if="formdata.card_scene==3" label="单次充值金额：">
            <Input v-model="formdata.recharge_money" style="width:200px"></Input><span class="ml20">此金额不计入成长值</span>
          </FormItem>
          <!-- <FormItem v-if="formdata.card_scene!=2" label="开卡图：">
            <div style="display:flex">
              <div>
                  <Upload
                  ref="upload"
                  :show-upload-list="false"
                  :format="['jpg','jpeg','png']"
                  :max-size="2048"
                  :headers="header"
                  :on-format-error="handleFormatError"
                  :on-success="uploadImgSuccess"
                  :on-progress="handleProgress"
                  multiple
                  :action="uploads">
                      <div class="uplads_box">
                          <div style="text-align:center">
                              <div style="margin-top:-10px"><Icon style="color:#9EA6B9" size="70" type="ios-add" /></div>
                              <div class="font14" style="margin-top:-10px;color:#9CA7B9">点击上传</div>
                          </div>
                      </div>
                  </Upload>
              </div>
              <div v-if="imgview" class="img_view_Box">
                <img :src="imgview" alt="">
              </div>
            </div>
          </FormItem> -->
          <FormItem label="制作表单：">
            <div class="form-pic" @click="addModel">
            <img src="../../../assets/imgs/7.png" height="90" width="84"/>
            </div>
          </FormItem>
          </span>
      </Form>
      <div class="bottom_box">
        <Button @click="addBtn" class="btn">保存</Button>
      </div>
    </div>
    <div class="need-card" v-if="needModel">
      <div class="pop"></div>
      <div class="pop-form">
        <div class="nav-title">制作表单</div>
        <div class="middle-form">
          <div class="left-icon">
            <div v-for="(item,index) in icon_list" :key="index" class="wrap" @click="addFrom(item.type)">
              <div class="item-pic"><img :src="item.src"/></div>
              <div class="item-title">{{ item.title }}</div>
            </div>
          </div>
          <div class="phone-model-bg">
            <div class="phone-model">
              <div class="phone-title">开卡表单</div>
              <div class="form-wrap">
                <div class="from-nav">
                  <div class="form-name">基本信息填写</div>
                  <div class="wrap-from">
                    <div class="wrap-from-item">
                      <div class="label-style"><span style="color:#FF761D">*</span> 姓名</div>
                      <div class="input-style">请输入姓名</div>
                    </div>
                    <div class="wrap-from-item"  @click="closeWin">
                      <div class="label-style"><span style="color:#FF761D">*</span>手机号</div>
                      <div class="input-style">请输入手机号</div>
                    </div>
                    <draggable
                        class="syllable_ul"
                        element="ul"
                        :list="fromList"
                        :options="{animation:150,disabled: false,preventOnFilter: true}"
                        :no-transition-on-drag="true"
                        @change="change"
                        @start="start"
                        @end="end"
                        :move="move"
                    >
                      <transition-group type="transition" :name="!drag? 'syll_li' : null" :css="true"> 
                        <div  v-for="(item,index) in fromList" :key="index"  :class="( keepIdx ==  index ? 'box-active ' : '' )+ ' wrap-from-item wrap-from-item-else' "
                             @click="editNav(item,index)">
                          <div class="label-style">
                            <span style="color:#FF761D" v-if="item.is_required">*</span>
                            {{ item.title }}
                          </div>
                          <div class="input-style" v-if="!item.option_title"><i v-if="item.type === '6'"
                                                                                class="iconfont icon-rili"></i>
                            {{ item.tips }} <i v-if="item.type === '5' || item.type === '6'"
                                               class="iconfont icon--arrow-down"></i></div>
                          <div class="only-choose" v-if="item.option_title && (item.type === '3' || item.type === '4')">
                            <div class="choose-item" v-for="(ite,idx) in item.option_title" :key="idx">
                              <i class="iconfont"
                                 :class="item.type === '3' ? 'icon-danxuanxuanzhong' : 'icon-duoxuan'"></i>{{
                                ite
                              }}
                            </div>
                          </div>
                          <div class="input-style" v-if="item.option_title && (item.type === '5')">
                            {{ item.tips }} <i class="iconfont icon--arrow-down"></i>
                          </div>
                          <div class="status-edit">
                            <!--                              <div class="iconfont icon-shangfan"></div>-->
                            <!--                              <div class="iconfont icon-xiafan"></div>-->
                            <div class="iconfont icon-fuzhi hover-class" @click.stop="copyIt(item)"></div>
                            <div class="iconfont icon-shanchu hover-classes" @click.stop="deleteIt(index)"></div>
                          </div>
                        </div>
                      </transition-group>
                    </draggable>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="right-edit" v-if="showRight">
            <div class="bold-title">基础设置</div>
            <div class="item-inp">
              <div class="label">标题</div>
              <Input v-model="rightItem.title" placeholder="请输入标题" style="width: 200px"></Input>
            </div>
            <div class="item-inp" v-if="rightItem.type === '1' || rightItem.type === '2'">
              <div class="label">输入框提示</div>
              <Input v-model="rightItem.tips" placeholder="请输入输入框提示" style="width: 200px"></Input>
            </div>
            <div class="item-inp">
              <div class="label">默认值</div>
              <Input v-model="rightItem.default_value" placeholder="请输入默认值" style="width: 200px"></Input>
            </div>
            <div class="item-inp" v-if="rightItem.type === '3' || rightItem.type === '4'|| rightItem.type === '5'">
              <div class="label">选项</div>
              <div class="r-flex" v-for="(child,index) in rightItem.option_title" :key="index">
                <Input v-model="rightItem.option_title[index]" placeholder="请输入选项" style="width: 150px"></Input>
                <div style="display: flex;align-items: center">
                  <span class="minus-icon iconfont icon-shanchu" @click="clearInput(index)"></span>
                </div>
              </div>
              <span class="add-icon iconfont icon-jia" @click="addInput"></span>
            </div>
            <div class="bold-title" style="margin-top: 20px">校正设置</div>
            <div class="more-than">
              <Checkbox v-model="rightItem.is_required">必填项目</Checkbox>
              <div style="font-size: 12px;color:#999999;margin-top: 5px;margin-left: 18px">将所有字段设为 <span class="color">必填项</span>
                或 <span class="color">非必填项</span></div>
            </div>
            <div class="more-than" v-if="rightItem.type === '1' || rightItem.type === '2'">
              <Checkbox v-model="rightItem.showLeast">
                最少填写<Input v-model="rightItem.least" placeholder=""
                           style="width: 50px;margin-left: 5px;margin-right: 5px"></Input>个字
              </Checkbox>
            </div>
            <div class="more-than" v-if="rightItem.type === '1' || rightItem.type === '2'">
              <Checkbox v-model="rightItem.showMost">
                最多填写<Input v-model="rightItem.most" placeholder=""
                           style="width: 50px;margin-left: 5px;margin-right: 5px"></Input>个字
              </Checkbox>
            </div>
          </div>
          <div class="right-edit" v-else>
              关闭手机号获取：
              <div>基本应用-》基本设置->登录授权手机号</div>
          </div>
        </div>
        <div class="foot">
          <div class="close-bottom-btn" @click="cancelForm">关闭</div>
          <div class="bottom-btn" @click="submitFrom">确认</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {bankInfo,listLevel,bankCard} from '@/api/user'
import config from '@/config';
import {getCookies} from '@/utils/helper'
import draggable from 'vuedraggable'
export default {
  name: "canteen",
  components: { draggable },
  data(){
    return{
      header:{
          'hlzw-canyin-authori-zation':getCookies('hlzw_canyin_token')
      },
      uploads: config.apiUrl + '/storeapi/data/uploads',
      Levellist:[],
      drag:false,
      needModel:false,
      showLeast:false,
      showMost:false,
      icon_list:[
        {
          src:require('../../../assets/imgs/1.png'),
          title:'输入框',
          type:'1'
        },
        {
          src:require('../../../assets/imgs/3.png'),
          title:'多行文本',
          type:'2'
        },
        {
          src:require('../../../assets/imgs/2.png'),
          title:'单选框',
          type:'3'
        },
        {
          src:require('../../../assets/imgs/4.png'),
          title:'多选框',
          type:'4'
        },
        {
          src:require('../../../assets/imgs/6.png'),
          title:'下拉选择',
          type:'5'
        },
        {
          src:require('../../../assets/imgs/5.png'),
          title:'日期选择',
          type:'6'
        },
      ],
      formItem: {
        input: '',
        phone: '',
        select: '',
        radio: 'male',
        checkbox: [],
        switch: true,
        date: '',
        time: '',
        slider: [20, 50],
        textarea: ''
      },
      rightItem:{
        title:'',
        tips:'',
        default_value:'',
        is_required:'',
        least:'',
        most:'',
      },
      fromList:[],
      imgview:'',
      formdata:{
        card_is_enable:0,
        // card_img:'',
        card_scene:1,
        card_is_force:2,
        card_level_id:null,
        card_pay_money:'0',
        recharge_money:0,
        set_form_data:[]
      },
      keepIdx:null,
      showRight:false
    }
  },
  created() {
    this.getbankInfo()
    this.listLevel()

    document.body.ondrop = function (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  },
  methods:{
    getbankInfo(){
      bankInfo().then(res=>{
        this.formdata = res.data
        this.imgview = res.data.new_card_img
        this.fromList = res.data.set_form_data
      })
    },
    closeWin(){
      this.keepIdx = null; 
      this.showRight = false
    },
    addModel(){
      this.needModel = true
    },
    submitFrom(){
      this.formdata.set_form_data = this.fromList
      this.needModel = false
    },
    cancelForm(){
      this.needModel = false
      this.closeWin()
    },
    addInput(){
      let idx = this.keepIdx
      this.fromList[idx].option_title.push('')
    },
    clearInput(index){
      let idx = this.keepIdx
      this.fromList[idx].option_title.splice(index,1)
    },
    addFrom(type){
      if(type === '1') {
        this.fromList.push(
            {
              title:'标题',
              default_value:'',
              tips:'请输入',
              type:'1',
              least:'',
              most:'',
              is_required:false
            }
        )
        return
      }
      if(type === '2') {
        this.fromList.push(
            {
              title:'多行文本',
              default_value:'',
              tips:'请输入',
              type:'2',
              least:'',
              most:'',
              is_required:false
            }
        )
        return
      }
      if(type === '3') {
        this.fromList.push(
            {
              title:'单选',
              default_value:'',
              tips:'请输入名称',
              type:'3',
              least:'',
              most:'',
              is_required:false,
              option_title:['单选1','单选2','单选3']
            },
        )
        return
      }
      if(type === '4') {
        this.fromList.push(
            {
              title:'多选',
              default_value:'',
              tips:'请输入名称',
              type:'4',
              least:'',
              most:'',
              is_required:false,
              option_title:['多选1','多选2','多选3']
            }
        )
      }
      if(type === '5') {
        this.fromList.push(
            {
              title:'下拉选择',
              default_value:'',
              tips:'请选择',
              type:'5',
              least:'',
              most:'',
              option_title:['下拉1','下拉2','下拉3'],
              is_required:false
            }
        )
        return
      }
      if(type === '6') {
        this.fromList.push(
            {
              title:'时间选择',
              default_value:'',
              tips:'请选择',
              type:'6',
              least:'',
              most:'',
              is_required:false
            }
        )
      }
    },
    change() {
    },
    //start ,end ,add,update, sort, remove 得到的都差不多
    start() {
      this.drag = true
    },
    end(evt) {
      this.drag = true
      evt.item //可以知道拖动的本身
      evt.to    // 可以知道拖动的目标列表
      evt.from  // 可以知道之前的列表
      evt.oldIndex  // 可以知道拖动前的位置
      evt.newIndex  // 可以知道拖动后的位置
    },
    move() {
    },
    copyIt(row){
      let val = JSON.parse(JSON.stringify(row))
      this.fromList.push(val)
    },
    deleteIt(idx) {
      this.fromList.splice(idx,1)
      this.showRight = false
    },
    listLevel(){
      listLevel().then(res=>{
        this.Levellist = res.data
      })
    },
    editNav(row,index){
      this.showRight = false
      this.$nextTick(()=>{
        this.rightItem = row
        this.keepIdx = index
        this.showRight = true
      })
     
    },
    addBtn(){

      bankCard(this.formdata).then(res=>{
        this.$Message.success(res.msg)
      }).catch(err=>{
        this.$Message.error(err.msg)
      })
    },
    //文件上传类型错误
    handleFormatError () {
        this.$Message.warning('暂不支持上传此格式');
    },
    //编辑图片上传图片成功
    uploadImgSuccess(e){
        this.imgview = e.data.path
        this.formdata.card_img = e.data.src
        this.$Message.destroy()
        this.$Message.success(e.msg);
    },
    // 文件上传时
    handleProgress(){
        const msg = this.$Message.loading({
            content: '上传中...',
            duration: 0
        });
        setTimeout(msg, 3000);
    },
  }
}
</script>

<style scoped>
.box-active{
  border-top:1px dashed #FF761D;
  border-bottom:1px dashed #FF761D !important;

}
 .son-page {
   word-break: break-all;
}
.content_box{
  position: relative;
  width: 100%;
  background: #FFF;
  height: 88vh;
  padding: 20px 30px;
  border-radius: 12px;
}
.bottom_box{
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #eee;
}
.btn{
  background:#FF761D;
  color:#FFF;
  border-color: #FF761D;
}
.ml20{
  margin-left: 20px;
}
.ivu-switch-checked{
  border-color: #FF761D;
  background-color: #FF761D;
}
.center{
  display: flex;
}
.txt{
  width: 80px;
  text-align: center;
}
.img_view_Box{
  width: 105px;
  height: 105px;
  border-radius: 5px;
  border: 1px solid #999;
  margin-left: 20px;
  overflow: hidden;
}
.img_view_Box img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.uplads_box{
    width: 105px;
    height: 105px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #A0A4BD;
    background: #F7F8FA;
    border-radius: 5px;
    cursor: pointer;
}
.form-pic {
  width: 236px;
  height: 170px;
  background: #FCFCFC;
  border: 4px solid #FFFFFF;
  box-shadow: 0px 0px 29px 0px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.need-card .pop {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 998;
  background: black;
  opacity: 0.4;
}
.need-card .pop-form {
  width: 70%;
  height: 90%;
  background: white;
  box-shadow: 0px 0px 29px 0px rgba(0, 0, 0, 0.07);
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: 999;
  border-radius: 6px;
}
.need-card .nav-title {
  font-size: 24px;
  color: #000000;
  padding: 16px 45px;
  box-sizing: border-box;
  border-bottom: 2px solid #E9E9E9;
}
.need-card .middle-form {
  height: 83%;
  width: 100%;
  display: flex;
}
.foot {
  padding: 12px 45px;
  box-sizing: border-box;
  border-top: 2px solid #E9E9E9;
  display: flex;
  align-items: center;
  justify-content: right;
}
.bottom-btn {
  width: 100px;
  height: 42px;
  background: #FF761D;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  font-size: 18px;
}
.close-bottom-btn {
  width: 100px;
  height: 42px;
  background: #ddd;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  font-size: 18px;
  margin-right:10px;
}
.left-icon {
  width: 18%;
  height: 100%;
  border-right:2px solid #E9E9E9;
}
.left-icon .wrap {
  display: inline-block;
  width: 50%;
}

.phone-model-bg {
  width: 55%;
  height: 100%;
  background: #E9E9E9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.phone-model {
  width: 338px;
  height: 654px;
  background: url("../../../assets/imgs/8.png") no-repeat;
  background-size: cover;
  padding: 30px 20px;
  box-sizing: border-box;
}
.phone-model .phone-title {
  font-size: 18px;
  color: black;
  text-align: center;
  margin-top: 15px;
}
.phone-model .form-wrap {
  background: #E9E9E9;
  margin-top: 15px;
  width: 100%;
  height: 90%;
  padding: 10px;
  box-sizing: border-box;
}
.phone-model .form-wrap .from-nav {
  background: #fff;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.item-pic {
  width: 60px;
  height: 60px;
  margin: auto;
  margin-top: 20px;
  cursor: pointer;
}
.item-pic img {
  width: 100%;
  height: 100%;
}
.item-title {
  text-align: center;
  font-size: 14px;
  color: #555555;
  margin-top: 8px;
}
.form-name {
  border-bottom: 1px dashed #C8C8C8;
  text-align: center;
  padding: 8px 0;
  font-size: 15px;
  color: #333333;
  margin-bottom: 20px;
}
.right-edit {
  padding: 46px 25px;
  box-sizing: border-box;
}
.bold-title {
  font-size: 22px;
  color: #000000;
}
.item-inp .label {
  font-size: 16px;
  color: #333333;
  margin-top: 12px;
  margin-bottom: 10px;
}
.wrap-from {
  padding: 0 12px;
  box-sizing: border-box;
}
.wrap-from-item {
  display: flex;
  align-items: center;
  padding: 10px 0;
  box-sizing: border-box;
  border-bottom: 1px solid #9EA6B9;
  position: relative;
}
.wrap-from-item-else {
  border-bottom: unset;
}
.wrap-from-item-else .input-style {
  background: #F8F8F8;
  height: 36px;
  position: relative;
}
.input-style .icon--arrow-down {
  position: absolute;
  right: 10px;
  top: 8px;
  font-size: 12px;
}

.input-style .icon-rili {
  font-size: 18px;
  margin-right: 5px;
}
.status-edit {
  width: 100%;
  height: 30px;
  position: absolute;
  left: 0;
  bottom: -30px;
  display: none;
  z-index: 999;
}
.status-edit .iconfont {
  width: 30px;
  height: 30px;
  background: white;
  color: #A4A4A4;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.18);
  margin-right: 12px;
}
/*.status-edit .iconfont:nth-child(2) {*/
/*  color: #FF4242;*/
/*}*/
/*.status-edit .iconfont:nth-child(3) {*/
/*  color: #94C717;*/
/*}*/
/*.status-edit .iconfont:nth-child(4) {*/
/*  color: #65B2FF;*/
/*}*/
.status-edit .hover-class {
  color: #94C717;
}
.status-edit .hover-class:hover {
  background: #94C717;
  color: white;
}
.status-edit .hover-classes {
  color: #65B2FF;
}
.status-edit .hover-classes:hover {
  background: #65B2FF;
  color: white;
}
.wrap-from-item:hover {
  background: #FFE4D2;
  cursor: pointer;
}
.wrap-from-item:hover .status-edit{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.wrap-from-item .label-style {
  font-size: 15px;
  width: 80px;
  color: #333333;
  margin-left: 4px;
}

.wrap-from-item .input-style {
  width: 160px;
  height: 28px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  border-radius: 5px;
}
.more-than {
  margin-top: 12px;
}
.color {
  color: #FF761D;
}
.only-choose {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
}
.choose-item .iconfont {
  margin-right: 5px;

}
.choose-item {
  margin-right: 8px;
}
.r-flex {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.add-icon {
  width: 30px;
  height: 30px;
  background: white;
  color: #2D8CF0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.18);
  cursor: pointer;
  border-radius: 4px;
}
.add-icon:hover {
  background: #2D8CF0;
  color: #fff;
}
.minus-icon {
  width: 30px;
  height: 30px;
  background: white;
  color: #FF4242;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.18);
  cursor: pointer;
  margin-left: 10px;
  border-radius: 4px;
}
.minus-icon:hover {
  background: #FF4242;
  color: #fff;
}
</style>
<style>
.item-inp .ivu-input {
  border: unset !important;
  background: #F8F8F8;
  height: 36px;
}
.phone-model .ivu-form-item {
  margin-bottom: 12px;
}
.else-move .ivu-select-dropdown {
  left: -35px!important;
}
</style>


