<template>
    <div  :id="id" :style="'height:'+height+'px;width:'+width"></div>
</template>
<script>
import * as echarts from 'echarts';
export default {
    name: 'echartPie',
    data(){
        return{

        }
    },
    props:{
        id:{
            type:String,
            default:'line'
        },
        width:{
            type:String,
            default:'100%'
        },
        height:{
            type:String, 
            default:'400'
        },
        title:{
            type:String,
            default: ''
        },
        num:{
            type:Number,
            default: null
        },
        name:{
            type:String,
            default: '统计数据'
        },
        color:{
            type:Array,
            default:['#5DAEFF','#FF761D','#92C411','#FF771C']
        },
        labelshow:{
            type:Boolean,
            default: false
        },
        data: {
            type:Array,
            default: function(){
                return [
                    {value: 1048, name: '搜索引擎'},
                    {value: 735, name: '直接访问'},
                    {value: 580, name: '邮件营销'},
                    {value: 484, name: '联盟广告'},
                    {value: 300, name: '视频广告'}
                ]
            }
        }
    },
    mounted(){
        this.initPie()
    },
    methods:{
        initPie(){
            let chartDom = document.getElementById(this.id);
            let myChart = echarts.init(chartDom);
            let option;

            option = {
                tooltip: {
                    trigger: 'item'
                },
                // legend: {
                //     orient: 'vertical',
                //     left: 'left',
                // },
                title:{
                    text:this.title,
                    left:"center",
                    top:"50%",
                    textStyle:{
                        color:"#999",
                        fontSize:14,
                        align:"center"
                    }
                },
                graphic:{
                    type:"text",
                    left:"center",
                    top:"40%",
                    style:{
                        text:this.num,
                        textAlign:"center",
                        fill:"#333",
                        fontSize:26,
                        fontWeight:800
                    }
                },
                series: [
                    {
                        name: this.name,
                        type: 'pie',
                        radius: ['40%', '70%'],
                        data: this.data,
                        color:this.color,
                        // color:['#5CAFFE','#FE58CA','#92C411','#FF771C'],
                        label: {
                            show:this.labelshow
                            // normal: {
                            //     position: 'inner'
                            // }
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ],
            };

            option && myChart.setOption(option);
            window.onresize = function () {
                myChart.resize();
            }
        }
    }
}
</script>