<template>
  <div class="son-page">
    <div class="content_box">
      <div class="left_box">
        <div class="right_btn">
          <Form :model="formData" :label-width="120">
            <FormItem label="月初增加余额：">
              <i-switch
                v-model="formData.is_able"
                :true-value="1"
                :false-value="0"
              >
                <span slot="open"></span>
                <span slot="close"></span>
              </i-switch>
              <Form
                style="margin-left: -17px"
                v-if="formData.is_able"
                :model="formData"
                :label-width="100"
              >
                <FormItem label="会员选择：" style="margin: 20px 0">
                  <RadioGroup
                    v-model="formData.status"
                    @on-change="radiochange"
                  >
                    <Radio :label="1">所有会员</Radio>
                    <Radio :label="0">选择会员</Radio> </RadioGroup
                  ><br />
                  <template v-if="formData.status == 0">
                    <Select
                      v-model="selectValue"
                      style="width: 700px; margin: 20px 0 20px 100px"
                      placeholder="选择会员"
                      multiple
                      filterable
                    >
                      <Option
                        v-for="item in memberList"
                        :value="item.id"
                        :key="item.id"
                        >{{ item.name }}/{{ item.phone }}</Option
                      >
                    </Select>
                  </template>
                </FormItem>
                <FormItem label="增加金额：">
                  <Input
                    v-model="formData.money"
                    placeholder="请输入金额"
                    style="width: 200px"
                  ></Input>
                </FormItem>
              </Form>
            </FormItem>
            <FormItem label="月末清除余额：">
              <i-switch
                v-model="formData.is_able_clean"
                :true-value="1"
                :false-value="0"
              >
                <span slot="open"></span>
                <span slot="close"></span>
              </i-switch>
            </FormItem>
          </Form>
          <div class="btns_box">
            <Button @click="saveTime" class="btn">保存</Button>
          </div>
        </div>
      </div>
    </div>
    <div class="table_box">
      <div class="yuan_FF761D DW_nei_txt">会员消费明细导出</div>
      <Form inline :label-width="100">
          <FormItem style="margin: 0" label="选择日期：">
            <DatePicker
              @on-change="time"
              type="daterange"
              placement="bottom-start"
              placeholder="点击选择日期"
              style="width: 200px"
            ></DatePicker>
          </FormItem>
          <FormItem style="margin: 0">
            <div style="display: flex">
              <Button @click="exportFun" >导出</Button>
            </div>
          </FormItem>
        </Form>
    </div>
    <div class="table_box">
      <div class="yuan_FF761D DW_nei_txt">运行记录</div>
      <Table stripe :columns="columns2" :data="CronList">
        <template slot-scope="{ index }" slot="id">
          <div>{{ index + 1 }}</div>
        </template>
      </Table>
      <br />
      <Page
        :total="total"
        :current="storePrams.page"
        style="text-align: right"
        show-elevator
        show-total
        @on-change="pageChange"
        :page-size="storePrams.limit"
      />
    </div>
  </div>
</template>

<script>
import { saveTime, getTime, getCronList, getSelectUser } from "@/api/user";
import config from '@/config';
import {getCookies} from '@/utils/helper'
export default {
  data() {
    return {
      uploads: config.apiUrl + '/storeapi/get_consumption_dtails',
      memberList: [],
      selectValue: [],
      formData: {
        // id: "",
        // data_id: "",
        is_able: "",
        is_able_clean: "",
        member_id: "",
        money: "",
        status: 0,
      },
      columns2: [
        {
          title: "#",
          slot: "id",
          align: "center",
        },
        {
          title: "运行时间",
          key: "created_at",
          align: "center",
        },
        {
          title: "类型",
          key: "type",
          align: "center",
        },
      ],
      CronList: [],
      total: 0,
      storePrams: {
        page: 1,
        limit: 30,
      },
      eportPrams:{
        start_time:'',
        end_time:''
      }
    };
  },
  mounted() {
    this.getSelectUserMember();
    this.getTimeFixed();
    this.getCronListMember();
  },
  methods: {
    radiochange() {
      console.log("111");
      this.selectValue = [];
    },
    //获取定时计划运行列表
    getCronListMember() {
      getCronList(this.storePrams)
        .then((res) => {
          this.CronList = res.data.data;
          this.total = res.data.total;
        })
        .catch((err) => {});
    },
    pageChange(index) {
      this.storePrams.page = index;
      this.getCronListMember();
    },
    //获取会员
    getSelectUserMember() {
      this.memberList = [];
      getSelectUser()
        .then((res) => {
          // this.memberList.push({ id: -1, name: "所有会员", phone: "" });
          res.data.forEach((item) => {
            this.memberList.push(item);
          });
        })
        .catch((err) => {});
    },
    //获取定时配置
    getTimeFixed() {
      getTime()
        .then((res) => {
          //   this.formData.id = res.data.id;
          //   this.formData.data_id = res.data.data_id;
          this.formData.is_able = res.data.is_able;
          this.formData.is_able_clean = res.data.is_able_clean;
          this.formData.member_id = res.data.member_id;
          this.formData.money = res.data.money;
          this.formData.status = res.data.member_id == "-1" ? 1 : 0;
          this.selectValue = res.data.member_id.split(",").map(Number);
        })
        .catch((err) => {
          console.log("getFixedTime", err);
        });
    },
    // 保存定时配置
    saveTime() {
      if (
        this.formData.is_able &&
        this.selectValue.length == 0 &&
        this.formData.status == 0
      ) {
        this.$Message.error("请选择会员");
        return;
      }
      if (this.formData.is_able && !this.formData.money) {
        this.$Message.error("请填写金额");
        return;
      }
      console.log("3333", this.selectValue);
      if (this.formData.is_able && this.formData.status) {
        this.formData.member_id = -1;
      } else {
        this.formData.member_id = this.selectValue.join();
      }
      saveTime(this.formData).then((res) => {
        if (res.code == 200) {
          this.$Message.success(res.msg);
        } else {
          this.$Message.warning(res.msg);
        }
      });
    },
    time(e){
      this.eportPrams.start_time = e[0]
      this.eportPrams.end_time = e[1]
    },
    exportFun(){
      if (!this.eportPrams.start_time || !this.eportPrams.end_time) {
        this.$Message.error("请选择时间");return;
      }
      location.href = this.uploads+'?token='+getCookies('hlzw_canyin_token')+'&start_time='+this.eportPrams.start_time+'&end_time='+this.eportPrams.end_time
    }
  },
};
</script>

<style scoped>
.content_box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.left_box {
  width: 100%;
  padding: 20px 30px;
  border-radius: 12px;
  background: #fff;
}
/* main */
.table_box {
  width: 100%;
  margin-top: 30px;
  background: #fff;
  border-radius: 12px;
  padding: 20px 30px;
}
.btn {
  background: #ff761d;
  color: #fff;
  border-color: #ff761d;
}
.btns_box {
  position: absolute;
  bottom: 0;
  right: 100px;
}
.right_btn {
  position: relative;
}
.yuan_FF761D::before {
  content: " ";
  display: block;
  width: 2px;
  height: 16px;
  margin-right: 10px;
  background: #ff761d;
}
.DW_nei_txt {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}
</style>