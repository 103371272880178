import request from '@/utils/request'

export function bankInfo(data) {
    return request({
        url: '/userSet/bankInfo',
        method: 'get',
        params: data
    })
}

export function bankCard(data) {
    return request({
        url: '/userSet/bankCard',
        method: 'post',
        data
    })
}

export function listLevel(data) {
    return request({
        url: '/userLevel/listLevel',
        method: 'get',
        params: data
    })
}

export function growthValueInfo() {
    return request({
        url: '/userSet/growthValueInfo',
        method: 'get'
    })
}

export function growthValue(data) {
    return request({
        url: '/userSet/growthValue',
        method: 'post',
        data
    })
}

export function userList(data) {
    return request({
        url: '/user/userList',
        method: 'get',
        params: data
    })
}

export function userBlock(data) {
    return request({
        url: '/user/userBlock',
        method: 'post',
        data
    })
}

export function moneyIndex(data) {
    return request({
        url: '/user/moneyIndex',
        method: 'get',
        params: data
    })
}

export function indexLevel(data) {
    return request({
        url: '/userLevel/indexLevel',
        method: 'get',
        params: data
    })
}

export function addLevel(data) {
    return request({
        url: '/userLevel/addLevel',
        method: 'post',
        data
    })
}

export function editLevel(data) {
    return request({
        url: '/userLevel/editLevel',
        method: 'post',
        data
    })
}

export function delLevel(data) {
    return request({
        url: '/userLevel/delLevel',
        method: 'post',
        data
    })
}

export function userUp(data) {
    return request({
        url: '/userSet/userUp',
        method: 'post',
        data
    })
}

export function getUserUp(data) {
    return request({
        url: '/userSet/getUserUp',
        method: 'get',
        params: data
    })
}

export function noticeInfo(data) {
    return request({
        url: '/userSet/noticeInfo',
        method: 'get',
        params: data
    })
}

export function notice(data) {
    return request({
        url: '/userSet/notice',
        method: 'post',
        data
    })
}

export function balanceChange(data) {
    return request({
        url: '/user/balanceChange',
        method: 'post',
        data
    })
}

export function orderIndex(data) {
    return request({
        url: '/user/orderIndex',
        method: 'get',
        params: data
    })
}

export function orderInfo(data) {
    return request({
        url: '/user/orderInfo',
        method: 'get',
        params: data
    })
}

export function homeIndex(data) {
    return request({
        url: '/user/homeIndex',
        method: 'get',
        params: data
    })
}

export function indexLevelGift(data) {
    return request({
        url: '/userLevel/indexLevelGift',
        method: 'get',
        params: data
    })
}

export function coupon_ls(data) {
    return request({
        url: '/apply/getCoupon',
        method: 'post',
        params: data
    })
}

export function addLevelGift(data) {
    return request({
        url: '/userLevel/addLevelGift',
        method: 'post',
        data
    })
}

export function delLevelGift(data) {
    return request({
        url: '/userLevel/delLevelGift',
        method: 'post',
        data
    })
}

export function editLevelGift(data) {
    return request({
        url: '/userLevel/editLevelGift',
        method: 'post',
        data
    })
}

export function updateLevel(data) {
    return request({
        url: '/user/updateLevel',
        method: 'post',
        data
    })
}

export function addUser(data) {
    return request({
        url: '/user/addUser',
        method: 'post',
        data
    })
}

export function delUser(data) {
    return request({
        url: '/user/delUser',
        method: 'post',
        data
    })
}


export function updateUser(data) {
    return request({
        url: '/user/updateUser',
        method: 'post',
        data
    })
}

export function get_form_info(data) {
    return request({
        url: '/user/get_form_info',
        method: 'get',
        params: data
    })
}

export function edit_form_info(data) {
    return request({
        url: '/user/edit_form_info',
        method: 'post',
        data
    })
}

export function getUserValList(data) {
    return request({
        url: '/user/getUserValList',
        method: 'post',
        data
    })
}

export function getUserIntegralList(data) {
    return request({
        url: '/user/getUserIntegralList',
        method: 'post',
        data
    })
}
/**
 * 
 * @returns  获取用户审核设置
 */
export function getExamineSetting() {
    return request({
        url: '/user/getExamineSetting',
        method: 'get'
    })
}

/**
 * 
 * @returns  保存用户审核设置
 */
export function saveExamineSetting(data) {
    return request({
        url: '/user/saveExamineSetting',
        method: 'post',
        data
    })
}

/**
 * 
 * @returns  获取用户审核列表
 */
export function getUserExamineList(data) {
    return request({
        url: '/user/getUserExamineList',
        method: 'post',
        data
    })
}

/**
 * 
 * @returns  用户审核状态修改
 */
export function saveUserExamineStatus(data) {
    return request({
        url: '/user/saveUserExamineStatus',
        method: 'post',
        data
    })
}
//保存定时配置
export function saveTime(data) {
    return request({
        url: '/user/save_time',
        method: 'post',
        data
    })
}
//获取定时配置
export function getTime() {
    return request({
        url: '/user/get_time',
        method: 'get'
    })
}
//获取定时计划运行列表
export function getCronList() {
    return request({
        url: '/user/get_cron_list',
        method: 'get'
    })
}
//会员下列接口
export function getSelectUser() {
    return request({
        url: '/user/get_select_user',
        method: 'get'
    })
}

